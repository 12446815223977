import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import user from '../../services/user'
import actions from './actions'
import readAsDataURL from '../../helpers/fileReader'

export function* LOGIN({ payload }) {
  const { accountID, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const encoded = btoa(password)
  const success = yield call(user.login, accountID, encoded)
  if (success) {
    const result = success.data
    if (result.meta.code === 201) {
      notification.success({
        message: 'Logged In',
        description: 'Anda berhasil login',
      })

      yield put({
        type: 'user/SET_STATE',
        payload: {
          login: result.data,
        },
      })
    } else {
      notification.warn({
        message: 'Login Gagal',
        description: 'Email atau password salah',
      })
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      login: null,
      loading: false,
      profile: null,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT({ payload }) {
  const { token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(user.profile, token)
  if (response) {
    const result = response.data
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profile: result,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LIST({ payload }) {
  const { token, params } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(user.list, token, params)
  if (response) {
    const result = response.data
    result.data.map((v, k) => {
      v.key = k
      return v
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        list: result,
      },
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { token, params } = payload
  params.depo_agent_id = 1
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(user.create, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 201) {
      notification.success({
        message: 'User',
        description: 'Berhasil menambahkan user baru',
      })
    } else {
      notification.error({
        message: 'User',
        description: 'Gagal menambahkan user baru',
      })
    }

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPLOAD_AVATAR({ payload }) {
  const { token, params } = payload

  /* User List */
  const userList = yield select(state => state.user.list)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = new FormData()
  data.append('file', params.file)
  data.append('user_id', params.user_id)

  const dataURL = yield call(readAsDataURL, params.file)
  const response = yield call(user.upload, token, data)
  if (response) {
    const result = response.data
    if (result.meta.code === 201) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          list: {
            ...userList,
            data: userList.data.map(i => {
              if (i.id !== params.user_id) return i
              i.avatar_path = dataURL
              return i
            }),
          },
        },
      })

      notification.success({
        message: 'Store',
        description: 'Berhasil mengunggah foto',
      })
    } else {
      notification.error({
        message: 'Store',
        description: 'Gagal mengunggah foto',
      })
    }

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SET_TOKEN({ payload }) {
  const { token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      token,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPLOAD_AVATAR, UPLOAD_AVATAR),
    takeEvery(actions.SET_TOKEN, SET_TOKEN),
  ])
}
