import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'
import schedule from '../../services/schedule'
import actions from './actions'

export function* LIST({ payload }) {
  const { token, params } = payload
  yield put({
    type: 'schedule/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(schedule.list, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return v
      })
      yield put({
        type: 'schedule/SET_STATE',
        payload: {
            list: result,
        },
      })
    }
    yield put({
      type: 'schedule/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPLOAD({ payload }) {
  const { token, params } = payload
  yield put({
    type: 'schedule/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = new FormData()
  data.append('file', params.file)

  const response = yield call(schedule.upload, token, data)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      message.success(`Document uploaded successfully.`)
    } else {
      message.success(`Failed upload document.`)
    }

    yield put({
      type: 'schedule/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.UPLOAD, UPLOAD),
  ])
}
