import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* OPEN({ payload }) {
  yield put({
    type: 'drawer/SET_STATE',
    payload: {
      active: payload.value,
    },
  })
}

export function* CLOSE() {
  yield put({
    type: 'drawer/SET_STATE',
    payload: {
      active: null,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.OPEN, OPEN)])
  yield all([takeEvery(actions.CLOSE, CLOSE)])
}
