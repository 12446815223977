export default async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      title: 'News',
      key: 'news',
      url: '/news',
      icon: 'icmn icmn-newspaper',
    },
    {
      title: 'Planogram',
      key: 'planogram',
      url: '/planogram',
      icon: 'icmn icmn-stats-bars',
    },
    {
      title: 'Store',
      key: 'store',
      url: '/store',
      icon: 'icmn icmn-cart',
    },
    {
      title: 'Schedule',
      key: 'schedule',
      url: '/schedule',
      icon: 'icmn icmn-database',
    },
    {
      title: 'User',
      key: 'user',
      url: '/users',
      icon: 'icmn icmn-user',
    },
    {
      title: 'Visit Schedule Report',
      key: 'visit-schedule-report',
      url: '/visit/schedule/report',
      icon: 'icmn icmn-calendar',
    },

    {
      title: 'Planogram Report',
      key: 'planogram-report',
      url: '/planogram-report',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Promo Eksekusi',
      key: 'promo-eksekusi',
      url: '/promo-eksekusi',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Checkin / Checkout Image',
      key: 'checkin-checkout-image',
      url: '/cc-image',
      icon: 'icmn icmn-exit',
    },
  ]
}
