import { all, select, takeEvery, takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from '../../services/store'
import actions from './actions'

// Helper
import setTagColor from '../../helpers/setTagColor'

function load(state) {
  return setState({ loading: state })
}

function get(key) {
  return select(state => state[key])
}

function setState(payload) {
  return put({
    type: 'store/SET_STATE',
    payload,
  })
}

export function* LIST({ payload }) {
  const { token, params } = payload

  yield load(true)

  const response = yield call(store.list, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.data_slice.map((v, k) => {
        v.key = k
        return v
      })

      yield setState({ list: result })
    }

    yield load(false)
  }
}

export function* CREATE({ payload }) {
  const { params } = payload
  const token = yield select(state => state.user.token)

  /* Format Date */
  ;[
    'visit_from_hour',
    'visit_until_hour',
    'operational_from_hour',
    'operational_until_hour',
  ].forEach(i => {
    params[i] = params[i].format('HH.mm')
  })
  params.store_owner_birth_date = params.store_owner_birth_date.format('YYYY-MM-DD')
  /* End of Format Date */

  yield load(true)

  const response = yield call(store.create, token, params)
  if (response.data.meta.code === 201)
    notification.success({
      message: 'Store',
      description: 'Berhasil menambahkan toko baru',
    })
  else {
    notification.error({
      message: 'Store',
      description: 'Gagal menambahkan toko',
    })
  }
  yield load(false)
}

/* Use this if the payload is exactly the response.data.data
 * It will set the 'name' as the key in the state
 * */
export function getParameter(name) {
  return function* GET() {
    const token = yield select(state => state.user.token)

    yield setState({
      [name]: {
        list: [],
        loading: true,
      },
    })

    const response = yield call(store[name], token)

    if (response) {
      const result = response.data
      if (name === 'visitActivity')
        result.data.map(i => {
          i.color = setTagColor(i.name)
          return i
        })

      if (result.meta.code === 200)
        yield setState({ [name]: { list: result.data, loading: false } })
    }
  }
}

export function* UPLOAD({ payload }) {
  const { params } = payload
  const token = yield select(state => state.user.token)
  yield load(true)

  const data = new FormData()
  data.append('file', params.file)
  data.append('store_id', params.store_id)

  const response = yield call(store.upload[params.type], token, data)

  if (response) {
    const result = response.data
    if (result.meta.code === 201) {
      notification.success({
        message: 'Store',
        description: 'Berhasil mengunggah foto',
      })
    } else {
      notification.error({
        message: 'Store',
        description: 'Gagal mengunggah foto',
      })
    }

    yield load(false)
  }
}

export function* DETAIL({ payload }) {
  const { params } = payload
  const token = yield select(state => state.user.token)
  yield setState({
    detail: {
      isLoading: true,
    },
  })
  const response = yield call(store.detail, token, params)
  yield setState({
    detail: {
      ...response.data.data,
      isLoading: false,
    },
  })
}

export function* OPERATIONAL_HOUR_LIST() {
  const token = yield select(state => state.user.token)
  const storeRedux = yield get('store')
  const { operationalNotes = {} } = storeRedux
  if (!operationalNotes.list) {
    yield setState({
      operationalNotes: {
        isLoading: true,
      },
    })
    const response = yield call(store.operationalNotes, token)
    if (response) {
      const result = response.data
      yield setState({ operationalNotes: { list: result.data, isLoading: false } })
    }
  }
}

export function* CLOSE_DETAIL() {
  const detail = yield select(state => state.store.detail)

  yield setState({
    detail: {
      ...detail,
      visible: false,
    },
  })
}

export function* OPEN_CREATE() {
  yield setState({
    create: {
      visible: true,
    },
  })
}

export function* CLOSE_CREATE() {
  yield setState({
    create: {
      visible: false,
    },
  })
}

export function* CURRENT({ payload }) {
  yield put({
    type: 'store/SET_STATE',
    payload: { id: payload.id },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LIST, LIST),
    takeEvery(actions.CATEGORY, getParameter('category')),
    takeEvery(actions.CLASSIFICATION, getParameter('classification')),
    takeEvery(actions.TYPE, getParameter('type')),
    takeEvery(actions.VISIT_ACTIVITY, getParameter('visitActivity')),
    takeEvery(actions.MDS_TYPE, getParameter('mdsType')),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPLOAD, UPLOAD),
    takeEvery(actions.DETAIL, DETAIL),
    takeLatest(actions.OPERATIONAL_HOUR_LIST, OPERATIONAL_HOUR_LIST),
    takeLatest(actions.CLOSE_DETAIL, CLOSE_DETAIL),
    takeLatest(actions.OPEN_CREATE, OPEN_CREATE),
    takeLatest(actions.CLOSE_CREATE, CLOSE_CREATE),
    takeLatest(actions.CURRENT, CURRENT),
  ])
}
