import axios from 'axios'

const apiURL = process.env.REACT_APP_API_URL
const headers = token => {
  const header = {
    'Content-Type': 'application/json',
    'Device-IMEI': '110011001100001',
  }

  if (token != null) header['Request-Access'] = token
  return header
}

const service = {
  post: (slug, body, token = null) =>
    axios.post(apiURL + slug, body, { headers: headers(token) }).catch(error => error.response),
  get: (slug, token = null, params = null) =>
    axios.get(apiURL + slug, {
      headers: headers(token),
      params,
    }),
}

export default service
