const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_TOKEN: 'user/SET_TOKEN',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  UPDATE_PROFILE: 'user/UPDATE_PROFILE',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  UPLOAD_AVATAR: 'user/UPLOAD_AVATAR',

  LIST: 'user/LIST',
  DETAIL: 'user/DETAIL',
  CREATE: 'user/CREATE',
}

export default actions
