import service from './service'

const store = {
  list: (token, params) => service.get(`/v1/admin/store/list`, token, params),

  category: token => service.get('/v1/admin/store/category/list', token),

  create: (token, params) => service.post('/v1/admin/store/create', params, token),

  classification: token => service.get('/v1/admin/store/classification/list', token),

  type: token => service.get('/v1/admin/store/type/list', token),

  visitActivity: token => service.get('/v1/admin/store/visit/activity/list', token),

  mdsType: token => service.get('/v1/admin/mds/type/list', token),

  upload: {
    'store-upload-image': (token, params) =>
      service.post('/v1/admin/store/image/upload', params, token),
    'store-upload-ktp': (token, params) =>
      service.post('/v1/admin/store/ktp/upload', params, token),
  },

  detail: (token, params) => service.get(`/v1/admin/store/detail/${params.id}`, token),
  operationalNotes: token => service.get('/v1/admin/store/operational/hour/note/list', token),
}

export default store
