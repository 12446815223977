import service from './service'

const schedule = {
  list: (token, params) =>
    service.get(`/v1/admin/schedule/upload/list`, token, params),

  upload: (token, params) =>
    service.post(`/v1/admin/schedule/upload`, params, token)
}

export default schedule
