import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import Loader from './components/Layouts/Loader'
import IndexLayout from './layouts'
import NotFoundPage from './pages/404'

const loadable = (loader, cookies) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
    render(loaded, props) {
      const Component = loaded.default
      return <Component {...props} cookies={cookies} />
    },
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: cookies => loadable(() => import('./pages/auth/login'), cookies),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard',
    component: cookies => loadable(() => import('./pages/dashboard/alpha'), cookies),
  },
  {
    path: '/news',
    component: cookies => loadable(() => import('./pages/news'), cookies),
  },
  {
    path: '/users',
    component: cookies => loadable(() => import('./pages/user'), cookies),
    exact: true,
  },
  {
    path: '/store',
    component: cookies => loadable(() => import('./pages/store'), cookies),
  },
  {
    path: '/schedule',
    component: cookies => loadable(() => import('./pages/schedule'), cookies),
  },
  {
    path: '/visit/schedule/report',
    component: cookies => loadable(() => import('./pages/visit-schedule-report'), cookies),
  },
  {
    path: '/planogram',
    component: cookies => loadable(() => import('./pages/planogram'), cookies),
  },
  {
    path: '/planogram-report',
    component: cookies => loadable(() => import('./pages/planogram-report'), cookies),
  },
  {
    path: '/promo-eksekusi',
    component: cookies => loadable(() => import('./pages/promo-eksekusi'), cookies),
  },
  {
    path: '/cc-image',
    component: cookies => loadable(() => import('./pages/checkin-checkout-img'), cookies),
  },
]

@connect()
class Router extends React.Component {
  componentDidMount() {
    const { cookies, dispatch } = this.props
    const token = cookies.get('token')
    dispatch({
      type: 'user/SET_TOKEN',
      payload: {
        token,
      },
    })
  }

  render() {
    const { history, cookies } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component(cookies)}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default withCookies(Router)
