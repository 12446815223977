const actions = {
  CATEGORY: 'store/CATEGORY',
  CREATE: 'store/CREATE',
  CURRENT: 'store/CURRENT',
  CLASSIFICATION: 'store/CLASSIFICATION',
  DETAIL: 'store/DETAIL',
  LIST: 'store/LIST',
  MDS_TYPE: 'mds/TYPE',
  TYPE: 'store/TYPE',
  UPLOAD: 'store/UPLOAD',
  SET_STATE: 'store/SET_STATE',
  VISIT_ACTIVITY: 'store/VISIT_ACTIVITY',
  OPERATIONAL_HOUR_LIST: 'store/OPERATIONAL_HOUR_LIST',
  CLOSE_DETAIL: 'store/CLOSE_DETAIL',
  OPEN_CREATE: 'store/OPEN_CREATE',
  CLOSE_CREATE: 'store/CLOSE_CREATE',
}

export default actions
