import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { FormattedMessage } from 'react-intl'
import { withCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const mapStateToProps = ({ user }, ownProps) => ({
  user,
  cookies: ownProps.cookies,
})

@connect(mapStateToProps)
class ProfileMenu extends React.Component {
  state = {
    count: 0,
  }

  componentWillMount() {
    const { dispatch, cookies } = this.props
    const token = cookies.get('token')

    if (token && token !== '') {
      dispatch({
        type: 'user/LOAD_CURRENT_ACCOUNT',
        payload: {
          token,
        },
      })
    }
  }

  logout = () => {
    const { dispatch, cookies } = this.props
    cookies.remove('token', { path: '/' })
    cookies.remove('expired_at', { path: '/' })
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user } = this.props
    const { count } = this.state
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />,{' '}
            {(user.profile ? user.profile.data.name : null) || 'Anonymous'}
          </strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to="/profile/update">
            <i className={`${styles.menuIcon} icmn-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Avatar
              className={styles.avatar}
              shape="square"
              size="large"
              icon={user.profile && user.profile.data.avatar ? '' : 'user'}
              src={user.profile ? user.profile.data.avatar : null}
            />
          </Badge>
        </div>
      </Dropdown>
    )
  }
}

export default withCookies(ProfileMenu)
