import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* open({ payload }) {
  yield put({
    type: 'modal/SET_STATE',
    payload: { type: payload.type },
  })
}

export function* close() {
  yield put({
    type: 'modal/SET_STATE',
    payload: { type: null },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.OPEN, open), takeEvery(actions.CLOSE, close)])
}
