import { all, put, call, takeEvery } from 'redux-saga/effects'
import getLeftMenuData from '../../services/menu'
import actions from './actions'

export function* GET_DATA({ payload }) {
  const menuLeftData = yield call(getLeftMenuData, payload)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
    },
  })
}

export default function* rootSaga() {
  // yield all([
  //   GET_DATA(), // run once on app load to fetch menu data
  // ])
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
