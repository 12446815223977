import actions from './actions'

export default function drawerReducer(
  state = {
    active: null,
  },
  action,
) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
