import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import schedule from './schedule/reducers'
import store from './store/reducers'
import modal from './modal/reducers'
import visitScheduleReport from './visit-schedule-report/reducers'
import area from './area/reducers'
import stock from './stock/reducers'
import region from './region/reducers'
import drawer from './drawer/reducers'
import visitPromoEksekusi from './visit-promo-eksekusi/reducers'
import visitPlanogramReport from './visit-planogram-report/reducers'
import visitCCImage from './visit-CC-image/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    area,
    user,
    drawer,
    menu,
    modal,
    schedule,
    stock,
    store,
    region,
    visitScheduleReport,
    visitPromoEksekusi,
    visitPlanogramReport,
    visitCCImage,
  })
