const tagColor = {
  'Check-In': '#99b898',
  'Stock Opname': '#feceab',
  'Competitor Scanning': '#ff847c',
  'Promotion Scanning': '#e84a5f',
  'Check-Out': '#8fcfd1',
  Planogram: '#f6efa6',
  Order: '#596e79',
  'Branding Scanning': '#fa26a0',
  'Menu Book Scanning': '#a2de96',
}

export default function(name) {
  return tagColor[name] || '#87d068'
}
