import service from './service'

const area = {
  search: (token, params) =>
    service.get(
      `/v1/admin/area/district/search/list?search_query=${params}&search_type=Name`,
      token,
    ),
}

export default area
