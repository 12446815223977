import { select, throttle, put, call } from 'redux-saga/effects'
import area from '../../services/area'
import actions from './actions'

function load(state) {
  return setState({ loading: state })
}

function getToken() {
  return select(state => state.user.token)
}

function setState(payload) {
  return put({
    type: 'area/SET_STATE',
    payload,
  })
}

export function* SEARCH({ payload }) {
  const { params } = payload
  const token = yield getToken()
  yield load(true)
  const response = yield call(area.search, token, params)
  const result = response.data
  yield setState({ dataSource: result.data })
  yield load(false)
}

export default function* rootSaga() {
  yield throttle(2000, actions.SEARCH, SEARCH)
}
