import { all } from 'redux-saga/effects'
import user from './user/sagas'
import drawer from './drawer/sagas'
import menu from './menu/sagas'
import modal from './modal/sagas'
import schedule from './schedule/sagas'
import store from './store/sagas'
import stock from './stock/sagas'
import area from './area/sagas'
import region from './region/sagas'
import visitScheduleReport from './visit-schedule-report/sagas'
import visitPromoEksekusi from './visit-promo-eksekusi/sagas'
import visitPlanogramReport from './visit-planogram-report/sagas'
import visitCCImage from './visit-CC-image/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    schedule(),
    drawer(),
    visitScheduleReport(),
    region(),
    stock(),
    store(),
    area(),
    modal(),
    visitPromoEksekusi(),
    visitPlanogramReport(),
    visitCCImage(),
  ])
}
