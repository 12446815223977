import service from './service'

const user = {
  login: (accountID, password) =>
    service.post(`/v1/guest/admin/login`, {
      account_id: accountID,
      password,
    }),
  profile: token => service.get(`/v1/admin/user/profile`, token),
  list: (token, params) => service.get(`/v1/admin/user/list`, token, params),
  create: (token, params) => service.post('/v1/admin/user/add/new', params, token),
  upload: (token, params) => service.post('/v1/admin/user/add/new/avatar', params, token),
}

export default user
