import { all, takeEvery, put, call } from 'redux-saga/effects'
import region from '../../services/region'
import actions from './actions'

export function* LIST({ payload }) {
  const { token, params } = payload
  yield put({
    type: 'region/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(region.list, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return v
      })
      yield put({
        type: 'region/SET_STATE',
        payload: {
          list: result,
        },
      })
    }
    yield put({
      type: 'region/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST)])
}
