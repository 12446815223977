import { all, select, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'
import stock from '../../services/stock'
import actions from './actions'
import readAsDataURL from '../../helpers/fileReader'

function load(state) {
  return setState({ loading: state })
}

function setState(payload) {
  return put({
    type: 'stock/SET_STATE',
    payload,
  })
}

export function* LIST({ payload }) {
  const { token, params } = payload
  yield load(true)

  const response = yield call(stock.list, token, params)
  if (response) {
    const result = response.data
    yield setState({
      list: result,
    })

    yield load(false)
  }
}

export function* UPLOAD_PLANOGRAM({ payload }) {
  const { token, params } = payload
  yield load(true)

  const stockList = yield select(state => state.stock.list)

  const data = new FormData()
  data.append('file', params.file)
  data.append(
    'data',
    JSON.stringify({
      stock_type_id: params.stock_type_id,
      mds_type_id: 1,
    }),
  )
  const dataURL = yield call(readAsDataURL, params.file)
  const response = yield call(stock.uploadPlanogram, token, data)
  if (response) {
    const result = response.data
    if (result.meta.message === 'Success') {
      yield put({
        type: 'stock/SET_STATE',
        payload: {
          list: {
            ...stockList,
            data: stockList.data.map(i => {
              if (i.id !== params.stock_type_id) return i
              i.store_planogram_default_image = dataURL
              return i
            }),
          },
          loading: false,
        },
      })

      message.success('Update default planogram berhasil!')
    } else {
      message.error('Update default planogram gagal!')
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST), takeEvery(actions.UPLOAD_PLANOGRAM, UPLOAD_PLANOGRAM)])
}
