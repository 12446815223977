import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import visitCCImage from '../../services/visit-cc-image'

export function* LIST({ payload }) {
  const { token, params } = payload
  yield put({
    type: 'visitCCImage/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(visitCCImage.list, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return k
      })
      yield put({
        type: 'visitCCImage/SET_STATE',
        payload: {
          list: result,
        },
      })
      yield put({
        type: 'visitCCImage/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* FILTER_TYPE({ payload }) {
  const { token } = payload
  yield put({
    type: 'visitCCImage/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(visitCCImage.filterType, token)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return k
      })
      yield put({
        type: 'visitCCImage/SET_STATE',
        payload: {
          _list: result,
        },
      })
      yield put({
        type: 'visitCCImage/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST), takeEvery(actions.FILTER_TYPE, FILTER_TYPE)])
}
