import { all, call, put, takeEvery } from 'redux-saga/effects'
import visitPlanogramReport from '../../services/visit-planogram-report'
import actions from './actions'

export function* LIST({ payload }) {
  const { token, params } = payload

  yield put({
    type: 'visitPlanogramReport/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(visitPlanogramReport.list, token, params)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return k
      })
      yield put({
        type: 'visitPlanogramReport/SET_STATE',
        payload: {
          list: result,
        },
      })
      yield put({
        type: 'visitPlanogramReport/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* FILTER_TYPE({ payload }) {
  const { token } = payload
  yield put({
    type: 'visitPlanogramReport/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(visitPlanogramReport.filterType, token)
  if (response) {
    const result = response.data
    if (result.meta.code === 200) {
      result.data.map((v, k) => {
        v.key = k
        return v
      })
      yield put({
        type: 'visitPlanogramReport/SET_STATE',
        payload: {
          filterType: result,
        },
      })
    }
    yield put({
      type: 'visitPlanogramReport/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST), takeEvery(actions.FILTER_TYPE, FILTER_TYPE)])
}
